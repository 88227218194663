import SectionHeading from "../heading/HeadingOne";
import SingleService from "./SingleService";
import ServicesData from '../../data/services/service-data.json';


export default function Services() {

    return(
        <div className="service-area">
            {/* Section Heading */}
            <SectionHeading
                // subtitle="5 years experienced"
                heading="OUR SERVICES"
                para="Insurance Services | Add New Service"
            />

            <div className="container">
                <div className="row justify-content-center g-4 g-xl-5">
                        {ServicesData && ServicesData.map((service) => {
                            return(
                                <SingleService 
                                    iconName={service.iconName}
                                    heading={service.heading}
                                    para={service.para}
                                    url={service.url}
                                    btnText={service.btnText}
                                    image={service.image}
                                    imageAlt={service.imageAlt}
                                />
                            )
                        })
                        }
                </div>
            </div>
        </div>
    )
}