
import { useState } from "react";
import useStickyHeader from "../header/StickyHeader";

export default function BreadcrumbTwo(props) {
    const {breadcrumbTitle, breadcrumbData} = props;
    let [check] = useState(true);    
    const sticky = useStickyHeader(90);
    const scrollClass = `${(sticky && check) ? 'scroll-from-top' : ''}`

    return(
        <div className={`breadcrumb-wrapper breadcrumb-bg-light ${scrollClass}`}>
            <div className="container h-100">
                <div className="row h-100 align-items-center">
                    <div className="col-12">
                        <div className="breadcrumb-content">
                            {/* Breadcrumb Title */}
                            <h2 className="breadcrumb-title">{breadcrumbTitle}</h2>
                        </div>
                        <h6 >{breadcrumbData}</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}