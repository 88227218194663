import Header from "../components/header/Header";
import BreadcrumbTwo from "../components/breadcrumb/BreadcrumbTwo";
import Footer from "../components/footer/Footer";
import ViewUsers from "../components/candidate/ViewUsers";
import GoogleMaps from "../components/contact/GoogleMaps";
import SEO from "../components/common/SEO";

export default function ViewCandidate() {
    return(
        <div>
            <SEO title="Details" />

            <Header 
                brandLogo="assets/img/bg-img/logo.png" 
                headerStyle="header-2" 
                buttonText="Log In" 
                buttonColor="btn-warning" 
            />

            <BreadcrumbTwo
                breadcrumbImage="" 
                breadcrumbTitle="View Candidates" 
                homePageUrl="/" 
                homePageText="Home" 
                currentPageText="details" 
            />

            <div className="saasbox-contact-us-area">
                <div className="container">
                    <div className="row g-4 justify-content-between">
                        <div className="col-12 col-lg-12">
                            <ViewUsers />
                        </div>
                    </div>
                </div>
            </div>

            <Footer 
                footerLogo="assets/img/bg-img/logo.jpeg" 
                footerStyle=""
            />
        </div>
    )
}