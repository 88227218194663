import SectionHeading from '../heading/HeadingOne';
import ScrollAnimation from 'react-animate-on-scroll';

export default function FeatureTwo() {

    const featuresData = [
        {
            "image": "assets/img/bg-img/no-capital-investment-1.png", 
            "imageAlt": "Minimum Investment",
            "title": "Minimum Investment",
            "animationDelay": 0
        },
        {
            "image": "assets/img/bg-img/no-inventory-stocking-1.png", 
            "imageAlt": "Big bunch of Products & Services",
            "title": "Big bunch of Products & Services",
            "animationDelay": 0
        },
        {
            "image": "assets/img/bg-img/repeat-customers-1.png", 
            "imageAlt": "Earn commission on every transaction",
            "title": "Earn commission on every transaction",
            "animationDelay": 0
        },
        {
            "image": "assets/img/bg-img/commision-orders-1.png", 
            "imageAlt": "Prestige & Customer Loyalty",
            "title": "Prestige & Customer Loyalty",
            "animationDelay": 0
        }
    ]

    const featuresItems = featuresData.map((ele, index) => (
        <div key={index} className="col-12 col-sm-6 col-lg-3">
            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" delay={ele.animationDelay} animateOnce={true}>
                <div className="card feature2-card shadow-lg">
                    <div className="card-body">
                        <div className={`feature-icon mb-4`}>
                            <img src={ele.image} alt={ele.imageAlt} />
                        </div>
                        <h5>
                            {ele.title}
                        </h5>
                    </div>
                </div>
            </ScrollAnimation>
        </div>
    ))

    return(
        <div className="feature-area feature2 pt-120 pb-120">            
            <SectionHeading 
                subtitle="Why To Chosse Apni Suvidha" 
                subtitleColor="text-dark" 
                heading="Benefits Of Joining Apni Suvidha" 
                paraColor="text-dark" 
            />
        
            <div className="container">
                <div className="row g-4 g-xxl-5">
                    {featuresItems}
                </div>
            </div>
        </div>
    )
}