import { Routes, Route, Navigate } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Services from "./pages/Services";
import AddCandidate from "./pages/AddCandidate";
import ViewCandidate from "./pages/ViewCandidate";

// All CSS Import
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/all-css-libraries.css";
import "../node_modules/react-modal-video/scss/modal-video.scss";
import "./assets/scss/style.scss";

export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route
          path="/services"
          element={
            localStorage.getItem("loggedIn") === "true" ? (
              <Services />
            ) : (
              <Navigate to="/home" />
            )
          }
        />
        {/* <Route path="/services/:productId" element={<ServicesDetails />} /> */}
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/add-candidate"
          element={
            localStorage.getItem("loggedIn") === "true" ? (
              <AddCandidate />
            ) : (
              <Navigate to="/home" />
            )
          }
        />
        <Route
          path="/view-candidates"
          element={
            localStorage.getItem("loggedIn") === "true" ? (
              <ViewCandidate />
            ) : (
              <Navigate to="/home" />
            )
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Routes>

      <ScrollToTop id="scrollTopButton" color="white" smooth top={200} />
    </div>
  );
}