import FooterCopywrite from "./FooterCopywrite";

export default function Footer() {

    return(
        <footer className={`footer-area footer-2 pt-40`}> 
            <FooterCopywrite 
                footerNavTwo={[
                    {
                        title: "Get Support", 
                        url: "/contact"
                    }
                ]}
                phone="Call: (+91) 1234567890" 
                email="Email: help@example.com" 
            />
        </footer>
    );
}