export default function FooterCopywrite() {

    return(
        <div className="">
            <div className="copywrite-wrapper mt-5 rounded d-lg-flex align-items-lg-center justify-content-lg-between">
                <div className="copywrite-text text-center text-lg-start mb-3 mb-lg-0 me-lg-4">
                    <p className="mb-0">
                        Copyright © 2022
                        <a style={{ color: "white"}}  href="http://www.apnisuvidha.com/" rel="noreferrer" target="_blank" > Apni Suvidha</a>
                        . All Rights Reserved.
                    </p>
                </div>
                <div className="footer-nav mb-3 mb-lg-0 me-lg-4">
                    <p className="mb-0">
                        Powered By : 
                        <a style={{ color: "white"}}  href="http://www.oktets.com/" rel="noreferrer" target="_blank" > Oktets Private Limited</a>
                    </p>
                </div>
            </div>
        </div>
    )
}