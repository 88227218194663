import Header from "../components/header/Header";
import BreadcrumbTwo from "../components/breadcrumb/BreadcrumbTwo";
import FeatureTwo from "../components/features/FeatureTwo";
import Footer from "../components/footer/Footer";
import AboutFour from "../components/about/AboutFour";
import SEO from "../components/common/SEO";

export default function AboutUs() {
    return(
        <div>
            <SEO title="About ApniSuvidha" />

            <Header 
                brandLogo="assets/img/bg-img/logo.png" 
                headerStyle="header-2" 
                buttonText="Log In" 
                buttonColor="btn-warning" 
            />

            <BreadcrumbTwo 
                breadcrumbTitle="About Us" 
                homePageUrl="/" 
                homePageText="Home" 
                currentPageText="About" 
            />

            <div className="container">
                <div className="about-us-section row g-4 g-lg-5">
                    <div className="col-12 col-sm-6 col-lg-6">
                        <h2>Why Apni Suvidha?</h2>
                        <h5>Apni Suvidha” intends to make availability of all kind of Services and Products to Citizen via its user at affordable and 
                            competitive Price. The Concept is simple “ to act as a bridge between Service Provider and Citizens” where availability of particular service provider is challenge 
                            and at the same time availability of Service / Product for the Citizen is challenge at affordable price range. It's aimed to serve the citizen with its unique bucket 
                            of services bringing service and products through its world class platform with help of technology<br/>
                            The journey is just begin and in comings days several new services and products will be added.
                        </h5>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6">
                        <img style={{maxHeight :'600px', display: 'block', margin: '0 auto', paddingTop: "45px"}} src={"assets/img/bg-img/egram-about-back.gif"} alt="rural gujarat" />
                    </div>
                </div>
            </div>

            <FeatureTwo />

            <AboutFour />

            <div className="container">
                <div className="border" />
            </div>

            <Footer 
                footerLogo="" 
                footerStyle=""
            />
        </div>
    )
}