import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

export default function Cta1(props) {
    const {CtaThumb, title, btnUrl, btnText, image, textColor} = props;

    return(
        <div className="cta-area pt-60 pb-60 bg-img bg-fixed bg-overlay" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/${CtaThumb})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-10 col-md-6">
                        {/* Cta Text */}
                        <div className="cta-text">
                            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" delay={100} animateOnce={true}>
                                <h2 style={{ color: "#ff8c42", marginBottom: "15px !importanta" }} className="mb-4 mb-lg-5">{title}</h2>
                            </ScrollAnimation>
                            <h5 style={{ color: textColor || "white" }}>Apnisuvidha” aimed to serve the citizen with its unique bucket of services bringing 
                            service and products through its world class platform with help of technology. It's intends to make availability of all kind of Services 
                            and Products to Citizen via its user at affordable and competitive Price.</h5>
                            <br/>
                            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" delay={300} animateOnce={true}>
                                <Link className="btn btn-warning read-more-btn" to={btnUrl} >
                                    {btnText}
                                </Link>
                            </ScrollAnimation>
                            
                        </div>
                    </div>
                    <div className="col-12 col-sm-10 col-md-6">
                        <img alt={`${title}`} style={{ float: 'right' }} src={`${process.env.PUBLIC_URL}/${image}`}></img>
                    </div>
                </div>
                </div>
        </div>
    )
}