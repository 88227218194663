import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function SingleService(props) {
    const {heading, para, image, imageAlt} = props;

    return(
        <div className="col-12 col-sm-6 col-lg-4">
            <Link to={localStorage.getItem("loggedIn") === "true" ? "/services" : "/login"}>
                <div className="card service-card">
                    <div className="card-body p-0 px-lg-0">
                        {para === "" && <Fragment><br/></Fragment>}
                        <div className={`icon`}>
                            <img src={image} alt={imageAlt}></img>
                        </div>
                        {para === "" && <Fragment><br/></Fragment>}
                        <h5>{heading}</h5>
                        {para === "" && <Fragment><br/></Fragment>}
                        <p>{para}</p>
                    </div>
                </div>
            </Link>
        </div>
    )
}