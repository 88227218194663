import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Services from "../components/services/Services";
import Cta1 from "../components/cta/CtaOne";
import SEO from "../components/common/SEO";
import Carousel from "react-bootstrap/Carousel";
import { useState, useEffect, Fragment } from "react";
import useStickyHeader from "../components/header/StickyHeader";
import { Spinner } from "react-bootstrap";
import { REACT_API_ENDPOINT } from "../configUrl";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function Home() {
  let [check] = useState(true);
  let [loading, setLoading] = useState(true);
  const sticky = useStickyHeader(90);
  const scrollClass = `${sticky && check ? "scroll-from-top" : ""}`;
  const search = useLocation().search;

  useEffect(() => {
      if( new URLSearchParams(search).get('siteId')){
        const loginData = {
            siteId:  new URLSearchParams(search).get('siteId').replace(/ /g, '+')
        };
        axios
          .post(`${REACT_API_ENDPOINT}/egram-login`, loginData, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then((response) => {
              // console.log("response",response)
            if (response.status === 200) {
              localStorage.setItem("username", response?.data?.gpName);
              localStorage.setItem("siteId", response?.data?.siteId);
              localStorage.setItem("loggedIn", "true");
              localStorage.setItem("token", response?.data?.token);
              setLoading(false);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message);
            localStorage.clear();
            setLoading(false);
          });  
      }else{
        setLoading(false);
      }
    }, []);

  return (
    <Fragment>
      {loading && (
        <div className="loader-parent">
          <Spinner className="loader" animation="border" />
        </div>
      )}
      <div className="creative-agency-wrap">
        <SEO title="Home" />
        <Header
          brandLogo="assets/img/bg-img/logo.png"
          headerStyle="header-2"
          buttonText="Log In"
          buttonColor="btn-warning"
        />

        <div className={scrollClass}>
          <Carousel>
            <Carousel.Item interval={5000}>
              <img
                className="d-block w-100"
                src="/assets/img/bg-img/gram1.jpeg"
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="d-block w-100"
                src="/assets/img/bg-img/gram2.jpeg"
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="d-block w-100"
                src="/assets/img/bg-img/gram10.jpeg"
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="d-block w-100"
                src="/assets/img/bg-img/gram3.jpeg"
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="d-block w-100"
                src="/assets/img/bg-img/gram4.jpeg"
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="d-block w-100"
                src="/assets/img/bg-img/gram5.jpeg"
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="d-block w-100"
                src="/assets/img/bg-img/gram6.jpeg"
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="d-block w-100"
                src="/assets/img/bg-img/gram8.jpeg"
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="d-block w-100"
                src="/assets/img/bg-img/gram9.jpeg"
                alt="First slide"
              />
            </Carousel.Item>
          </Carousel>
        </div>

        {/* <BreadcrumbOne
                    breadcrumbTitle="About Apni Suvidha" 
                    homePageUrl="/" 
                    homePageText="Home" 
                    currentPageText="About" 
                /> */}

        <Services />

        <Cta1
          CtaThumb={"assets/img/bg-img/parallax-bg-3.jpg"}
          image={"assets/img/bg-img/about-animation.gif"}
          title="About Apni Suvidha"
          data="Test"
          btnUrl="/about-us"
          btnText="Read More"
        />

        <Footer footerLogo="assets/img/bg-img/logo.jpeg" footerStyle="" />
      </div>
      <ToastContainer />
    </Fragment>
  );
}
