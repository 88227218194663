import { useNavigate } from 'react-router-dom';
import { Fragment, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { REACT_API_ENDPOINT } from '../../configUrl';
import axios from "axios";

const LoginForm = props => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const navigate = useNavigate();

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      login();
    }
  };

  const login = (e) => {
    e.preventDefault();
    setLoading(true);
    const loginData = {
      username: email,
      password: registerPassword
    };

    const url = `${REACT_API_ENDPOINT}/login`;
    axios
      .post(url, loginData, {
        headers: { "Access-Control-Allow-Origin": "*" }
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("username", response?.data?.username);
          localStorage.setItem("loggedIn", "true");
          localStorage.setItem("token", response?.data?.token);
          window.location.replace("/home")
          // navigate("/home");
        } else {
          localStorage.clear();
          navigate("/login");
          setLoading(false);
          toast.error('Something went wrong');
        }
      })
      .catch((error) => {
        localStorage.clear();
        navigate("/login");
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <Fragment>
      {loading && (
        <div className="loader-parent">
          <Spinner className="loader" animation="border" />
        </div>
      )}
      <div className="register-form my-4 my-lg-5">
        {/* Login Form */}
        <form>
          <div className="form-group mb-3">
            <input
              className="form-control"
              value={email}
              type="text"
              name="email"
              placeholder="Username"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <input
              className="form-control"
              value={registerPassword}
              id="registerPassword"
              name="registerPassword"
              type="password"
              placeholder="Password"
              required
              onChange={(e) => setRegisterPassword(e.target.value)}
            />
          </div>
          <button
            className="btn btn-primary w-100"
            onClick={login}
            disabled={email.length > 0 && registerPassword.length > 0 ? false : true}
            onKeyDown={onKeyDown}
          >
            <i className="bi bi-unlock me-2" />
            Login
          </button>
        </form>
      </div>
      <ToastContainer />
    </Fragment>
  );
}

export default LoginForm;