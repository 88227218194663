import { useNavigate } from "react-router-dom";
import { Fragment, useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { REACT_API_ENDPOINT } from "../../configUrl";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

export default function ViewUsers() {
  const [usersData, setUsersData] = useState([]);
  
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${REACT_API_ENDPOINT}/candidate`, {
        headers: {
          "Authorization": token,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setUsersData(res?.data?.Items)
        }
      })
      .catch((err) => {
        if (err.status >= 400 && err.status <= 499) {
          localStorage.clear();
          window.location.replace("/login");
        }else{
          toast.error("Error authorizing the user!");
        }
      });
  };

  return (
    <div>
      {usersData && usersData.length > 0 ? (
        <Table className="table-hover table-striped">
          <thead>
            <tr>
              <th className="border-0">ID</th>
              <th className="border-0">Name</th>
              <th className="border-0">Contact Number</th>
              <th className="border-0">Interested Industry</th>
              <th className="border-0">Location</th>
              <th className="border-0">Qualifications</th>
              <th className="border-0">Village</th>
              <th className="border-0">Taluka</th>
              <th className="border-0">District</th>
              <th className="border-0">Resume</th>
            </tr>
          </thead>
          <tbody>
            {usersData.map((user, i) => {
              return (
                <tr>
                  <td>{i + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.contactNumber}</td>
                  <td>{user.industry}</td>
                  <td>{user.preferLocation}</td>
                  <td>{user.qualifications}</td>
                  <td>{user.village}</td>
                  <td>{user.taluka}</td>
                  <td>{user.district}</td>
                  <td>
                    {user.fileUploadUrl ? (
                      <a
                        href={`https://apnisuvidha-assets.s3.ap-south-1.amazonaws.com/${user.fileUploadUrl}`}
                        target="_blank"
                      >
                        View & Download
                      </a>
                    ) : (
                      <>-</>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <h2>
          No candidates added. Please click{" "}
          <Link to="/add-candidate">
            <u>here</u>
          </Link>{" "}
          add new candidates.
        </h2>
      )}
    </div>
  );
}
