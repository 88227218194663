import SectionHeading from '../heading/HeadingOne';

export default function AboutFour() {

    const aboutData = [
        {
            image: "assets/img/bg-img/insurance.png",
            title: "Insurance",
        },
        {
            image: "assets/img/bg-img/add.png",
            title: "Add a Service",
        }
    ]
    
    const aboutCards = aboutData.map((ele, index) => (
        <div key={index} className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <div className="card hero-card h-100 border-0 p-3">
                <div className="card-body">
                    <img src={ele.image} alt={ele.title} />
                    <h5>{ele.title}</h5>
                </div>
            </div>
        </div>
    ))

    return(
        <div className="about-area about3">
            <div className="container">
                <div className="row justify-content-center g-3 g-lg-4">
                    <SectionHeading 
                        subtitleColor="text-dark" 
                        heading="Apni Suvidha Services" 
                        paraColor="text-dark" 
                    />
                    {aboutCards}
                </div>
            </div>
        </div>
    )
}