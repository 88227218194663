import { useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { REACT_API_ENDPOINT } from "../../configUrl";
import axios from "axios";

export default function DetailsForm() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [industry, setIndustry] = useState("");
  const [preferLocation, setpreferLocation] = useState("");
  const [village, setVillage] = useState("");
  const [taluka, setTaluka] = useState("");
  const [district, setDistrict] = useState("");
  const [qualifications, setQualifications] = useState("");
  const [resumeFileType, setResumeFileType] = useState("");
  const [resumeFile, setResumeFile] = useState(null);
  const navigate = useNavigate();

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addCandidate();
    }
  };

  const uploadFiletoS3 = async (res) => {
    console.log(res)
    if (res.fileUploadUrl) {
      const result = await fetch(res.fileUploadUrl, {
        method: "PUT",
        body: resumeFile,
      });
    }
  };

  const handleAttachChangeFile = (e) => {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function (event) {
      setResumeFileType(file.type.split("/")[1]);
      setResumeFile(event.target.result);
    };
    reader.readAsArrayBuffer(file);
  };

  const addCandidate = async () => {
    if (
      name == "" ||
      contactNumber == "" ||
      industry == "" ||
      preferLocation == "" ||
      qualifications == "" ||
      resumeFileType == "" ||
      village == "" ||
      taluka == "" ||
      district == ""
    ) {
      toast.error("All field are mandatory");
      return;
    }
    // setLoading(true);
    const userData = {
      name: name,
      contactNumber: contactNumber,
      industry: industry,
      preferLocation: preferLocation,
      village: village,
      taluka: taluka,
      district: district,
      qualifications: qualifications,
      resumeFileType: resumeFileType,
    };
    axios
      .post(`${REACT_API_ENDPOINT}/candidate`, userData, {
        headers: {
          "Authorization": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(async (response) => {
        if (response.status == 200) {
          await uploadFiletoS3(response.data);
          setLoading(false);
          toast.success("User added successfully!!");
          navigate("/view-candidates");
        }
      })
      .catch((err) => {
        if (err.status >= 400 && err.status <= 499) {
          localStorage.clear();
          window.location.replace("/login");
        }else{
          toast.error("Error while adding user");
          setLoading(false);
        }
      });
  };

  return (
    <div className="contact-form">
      <ToastContainer />
      {loading && (
        <div className="loader-parent">
          <Spinner className="loader" animation="border" />
        </div>
      )}
        <div className="row">
          {/* Form Control */}
          <div className="col-12 col-lg-6">
            <label className="form-label" htmlFor="name">
              Name:
            </label>
            <input
              className="form-control mb-30"
              id="name"
              type="text"
              placeholder="Enter Name"
              name="name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          {/* Form Control */}
          <div className="col-12 col-lg-6">
            <label className="form-label" htmlFor="email">
              Contact Number:
            </label>
            <input
              className="form-control mb-30"
              id="contactNumber"
              type="number"
              placeholder="Enter contactNumber"
              name="contactNumber"
              value={contactNumber}
              required
              onChange={(e) => setContactNumber(e.target.value)}
            />
          </div>

          {/* Form Control */}
          <div className="col-12 col-lg-6">
            <label className="form-label" htmlFor="subject">
              Interested to work in which industry :
            </label>
            <input
              className="form-control mb-30"
              id="industry"
              type="text"
              placeholder="Enter Industry"
              name="industry"
              value={industry}
              required
              onChange={(e) => setIndustry(e.target.value)}
            />
          </div>

          {/* Form Control */}
          <div className="col-12 col-lg-6">
            <label className="form-label" htmlFor="subject">
              Which location:
            </label>
            <input
              className="form-control mb-30"
              id="location"
              type="text"
              placeholder="Enter Location"
              name="location"
              required
              value={preferLocation}
              onChange={(e) => setpreferLocation(e.target.value)}
            />
          </div>

          {/* Form Control */}
          <div className="col-12 col-lg-6">
            <label className="form-label" htmlFor="subject">
              Qualifications:
            </label>
            <input
              className="form-control mb-30"
              id="qualifications"
              type="text"
              placeholder="Enter Qualifications"
              name="qualifications"
              required
              value={qualifications}
              onChange={(e) => setQualifications(e.target.value)}
            />
          </div>

          {/* Form Control */}
          <div className="col-12 col-lg-6">
            <label className="form-label" htmlFor="subject">
              Upload Resume:
            </label>
            <input
              className="form-control mb-30"
              id="resumeFile"
              type="file"
              placeholder="Upload File"
              name="resumeFile"
              required
              onChange={handleAttachChangeFile}
            />
          </div>

          {/* Form Control */}
          <div className="col-12 col-lg-4">
            <label className="form-label" htmlFor="subject">
              Native village:
            </label>
            <input
              className="form-control mb-30"
              id="village"
              type="text"
              placeholder="Enter Village"
              name="village"
              required
              value={village}
              onChange={(e) => setVillage(e.target.value)}
            />
          </div>

          {/* Form Control */}
          <div className="col-12 col-lg-4">
            <label className="form-label" htmlFor="subject">
              Taluka:
            </label>
            <input
              className="form-control mb-30"
              id="taluka"
              type="text"
              placeholder="Enter Taluka"
              name="taluka"
              required
              value={taluka}
              onChange={(e) => setTaluka(e.target.value)}
            />
          </div>

          {/* Form Control */}
          <div className="col-12 col-lg-4">
            <label className="form-label" htmlFor="subject">
              District:
            </label>
            <input
              className="form-control mb-30"
              id="district"
              type="text"
              placeholder="Enter District"
              name="district"
              required
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
            />
          </div>

          {/* Form Control */}
          <div className="col-12 text-center">
            <button
              className="btn btn-primary w-100"
              type="submit"
              onKeyDown={() => onKeyDown()}
              onClick={() => addCandidate()}
            >
              Update Now
            </button>
          </div>
        </div>
    </div>
  );
}
