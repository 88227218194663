import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { REACT_API_ENDPOINT } from '../../configUrl';
import axios from "axios";

export default function ProductCard(props) {
    const [loading, setLoading] = useState(false);
    const {productColumn, name, domain, logoFileName, redirectUrl, authUrl} = props;
    const handleRedirect = () => {
        if(!authUrl) {
            const newWindow = window.open(redirectUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null;
            return
        }
        setLoading(true);
        const token = localStorage.getItem("token");
        if(!token) {
            setLoading(false);
            return;
        }
        axios
            .get(`${REACT_API_ENDPOINT}${authUrl}`, {
                headers: {
                  'Authorization': token
                }
            })
            .then((res) => {
                setLoading(false);
                let siteId = res?.data?.siteId;
                const newWindow = window.open(`${redirectUrl}?siteId=${siteId}`, '_blank', 'noopener,noreferrer')
                // console.log(`${redirectUrl}?siteId=${siteId}`)
                if (newWindow) newWindow.opener = null;
            })
            .catch((err) => {
                if (err.status >= 400 && err.status <= 499) {
                    localStorage.clear();
                    window.location.replace("/login");
                }else{
                    toast.error('Error authorizing the user!');
                    setLoading(false);
                }
                
            })
    }
    
    return(
        <>
            {loading && (
                <div className="loader-parent">
                <Spinner className="loader" animation="border" />
                </div>
            )}
            <div className={productColumn}>
                {redirectUrl !== "" ?
                    <div style={{cursor: 'pointer'}} onClick={() => handleRedirect()} target="_blank">
                        <div className="card shop-card">
                        {/* Product Image */}
                            <div className="product-img-wrap">
                                <img className="card-img-top" src={logoFileName} alt={name} />
                            </div>

                            {/* Product Meta */}
                            <div className="product-meta d-flex align-items-center justify-content-between p-4">
                                <div className="product-name">
                                    <h5 style={{ color: "white" }}><b>{name}</b></h5>
                                    <h6 style={{ color: "white" }}>{domain}</h6>
                                </div>
                            </div>
                        </div>
                    </div> 
                :
                    <div className="card shop-card">
                        {/* Product Image */}
                        <div className="product-img-wrap">
                            <img className="card-img-top" src={logoFileName} alt={name} />
                        </div>

                        {/* Product Meta */}
                        <div className="product-meta d-flex align-items-center justify-content-between p-4">
                            <div className="product-name">
                                <h5 style={{ color: "white" }}><b>{name}</b></h5>
                                <h6 style={{ color: "white" }}>{domain}</h6>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <ToastContainer />
        </>
    )
}