import { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import useStickyHeader from "./StickyHeader";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

export default function Header(props) {
  const { brandLogo, headerStyle } = props;
  const [isActive, setIsActive] = useState(
    window.location.pathname.split("/").pop()
  );

  let [check] = useState(true);
  const sticky = useStickyHeader(90);
  const stickyClass = `${sticky && check ? "sticky" : ""}`;

  return (
    <header className={`header-area ${headerStyle} ${stickyClass}`}>
      <Navbar expand="lg">
        <div
          className="container"
          style={{ margin: "0px 20px", maxWidth: "100%" }}
        >
          {/* Navbar Brand */}
          <Link className="navbar-brand" to="/home">
            <img
              src={`${process.env.PUBLIC_URL}/${brandLogo}`}
              alt="Apni Suvidha"
            />
          </Link>

          {/* Navbar Toggler */}
          <Navbar.Toggle
            className="navbar-toggler"
            aria-controls="saasboxNav"
          />

          <Navbar.Collapse id="saasboxNav">
            {/* Nav */}
            <div className={isActive === "home" ? "active-tab" : ""}>
              <div
                onClick={() => setIsActive("home")}
                style={{ margin: "15px", fontSize: "22px" }}
              >
                <Link to="/home">
                  <b className={isActive === "home" ? "active-tab-text" : ""}>
                    Home
                  </b>
                </Link>
              </div>
            </div>
            {localStorage.getItem("loggedIn") === "true" && (
              <div className={isActive === "services" ? "active-tab" : ""}>
                <div
                  onClick={() => setIsActive("services")}
                  style={{ margin: "15px", fontSize: "22px" }}
                >
                  <Link to="/services">
                    <b
                      className={
                        isActive === "services" ? "active-tab-text" : ""
                      }
                    >
                      Services
                    </b>
                  </Link>
                </div>
              </div>
            )}
            {localStorage.getItem("loggedIn") == "true" && (
              <div
                className={
                  isActive == "view-candidates" || isActive == "add-candidate"
                    ? "active-tab"
                    : ""
                }
              >
                <div style={{ margin: "15px" }}>
                  <DropdownButton id="dropdown-basic-button" title="Candidate">
                    <Dropdown.Item>
                      <Link to="/view-candidates">
                        <div>
                          <b
                            onClick={() => setIsActive("view-candidates")}
                            className={
                              isActive == "view-candidates"
                                ? "active-tab-text"
                                : ""
                            }
                          >
                            View
                          </b>
                        </div>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/add-candidate">
                        <div>
                          <b
                            onClick={() => setIsActive("add-candidate")}
                            className={
                              isActive == "add-candidate"
                                ? "active-tab-text"
                                : ""
                            }
                          >
                            Add
                          </b>
                        </div>
                      </Link>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            )}
            <div className={isActive === "about-us" ? "active-tab" : ""}>
              <div
                onClick={() => setIsActive("about-us")}
                style={{ margin: "15px", fontSize: "22px" }}
              >
                <Link to="/about-us">
                  <b
                    className={isActive === "about-us" ? "active-tab-text" : ""}
                  >
                    About Us
                  </b>
                </Link>
              </div>
            </div>
            <div className={isActive === "contact" ? "active-tab" : ""}>
              <div
                onClick={() => setIsActive("contact")}
                style={{ margin: "15px", fontSize: "22px" }}
              >
                <Link to="/contact">
                  <b
                    className={isActive === "contact" ? "active-tab-text" : ""}
                  >
                    Contact
                  </b>
                </Link>
              </div>
            </div>
            {/* <div className={isActive == "partners" ? "active-tab" : ""}>
                            <div onClick={() => setIsActive("partners")} style={{ margin: "15px", fontSize: "22px" }}>
                                <Link to="/partners">
                                    <b className={isActive == "partners" ? "active-tab-text" : ""}>Partners</b>
                                </Link>
                            </div>
                        </div> */}

            {/* Button */}
            <div
              className="ms-auto mb-3 mb-lg-0"
              style={{ paddingRight: "20px" }}
            >
              {localStorage.getItem("loggedIn") === "true" && (
                <h6 className="ms-auto mb-3 mb-lg-0">
                  Welcome {localStorage.getItem("username")}
                </h6>
              )}
            </div>
            {localStorage.getItem("loggedIn") === "true" ? (
              <div className="login-btn" onClick={() => {
                  localStorage.clear();
                  window.location.replace("/login");
                }}>
                  <div className="login-text btn btn-sm">Logout</div>
              </div>
            ) : (
              <div className="login-btn">
                <Link className={`login-text btn btn-sm`} to="/login">
                  Login
                </Link>
              </div>
            )}
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
}
