import Header from "../components/header/Header";
import BreadcrumbTwo from "../components/breadcrumb/BreadcrumbTwo";
import Footer from "../components/footer/Footer";
import ContactSideInfo from "../components/contact/ContactSideInfo";
import ContactForm from "../components/contact/ContactForm";
import GoogleMaps from "../components/contact/GoogleMaps";
import SEO from "../components/common/SEO";

export default function Contact() {
    return(
        <div>
            <SEO title="Contact" />

            <Header 
                brandLogo="assets/img/bg-img/logo.png" 
                headerStyle="header-2" 
                buttonText="Log In" 
                buttonColor="btn-warning" 
            />

            <BreadcrumbTwo
                breadcrumbImage="" 
                breadcrumbTitle="Contact Us" 
                homePageUrl="/" 
                homePageText="Home" 
                currentPageText="Contact" 
            />

            <div className="saasbox-contact-us-area">
                <div className="container">
                    <div className="row g-4 justify-content-between">
                        <div className="col-12 col-lg-5 col-xl-4">
                            <ContactSideInfo />
                        </div>

                        {/* Contact Form*/}
                        <div className="col-12 col-lg-7">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>

            <GoogleMaps 
                mapSource="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.7017021174!2d72.65456481548993!3d23.21753921483816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c2ba5841a244b%3A0x37a53e56122677b2!2sSachivalay%2C%20Gandhinagar!5e0!3m2!1sen!2sus!4v1643957953753!5m2!1sen!2sus"
            />
            <Footer 
                footerLogo="assets/img/bg-img/logo.jpeg" 
                footerStyle=""
            />
        </div>
    )
}