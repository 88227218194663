import { useState, useEffect } from "react";
import Header from "../components/header/Header";
import BreadcrumbTwo from "../components/breadcrumb/BreadcrumbTwo";
import Footer from "../components/footer/Footer";
import ProductCard from "../components/shop/ProductCard";
import ShopData from "../data/shop/shop-data.json";
import SEO from "../components/common/SEO";
import { Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom";

export default function Services() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const shopLists = ShopData.map((elem, index) => (
        <ProductCard 
            productColumn="col-12 col-sm-6 col-lg-4"
            key={index}
            id={elem.id}
            logoFileName={`assets/img/bg-img/${elem.logoFileName}`}
            name={elem.name}
            domain={elem.domain}
            redirectUrl={elem.redirectUrl}
            authUrl={elem.authUrl}
        />
    ))

    useEffect(() => {
        if(localStorage.getItem("loggedIn") === null || localStorage.getItem("loggedIn") === undefined){
            navigate("/home");
        }
    }, [loading])
    
    return(
        <div>
            {loading && 
                <div className="loader-parent">
                    <Spinner className="loader" animation="border"/>
                </div>
            }
            <SEO title="Services" />

            <Header 
                brandLogo="assets/img/bg-img/logo.png" 
                headerStyle="header-2" 
                buttonText="Log in" 
                buttonColor="btn-danger" 
            />

            <BreadcrumbTwo
                breadcrumbImage="assets/img/bg-img/parallax-bg-3.jpg" 
                breadcrumbTitle="Services" 
                homePageUrl="/home" 
                homePageText="Home" 
                currentPageText="Services" 
            />

            <div className="shop-area shop-fullwidth">
                <div className="container">
                    <div className="row g-4 g-lg-5">
                        {shopLists}
                    </div>
                </div>
            </div>

            <Footer 
                footerLogo="assets/img/bg-img/logo.jpeg" 
                footerStyle="footer-2"
            />
        </div>
    )
}